module services {
    export module costing {

        export class costParameterProductCategoryOriginCountryService implements interfaces.costing.ICostParameterProductCategoryOriginCountryService {

            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getProductCategoryOriginCountryList(): ng.resource.IResourceClass<interfaces.costing.ICostProductCategoryOriginCountry> {
                return this.$resource<interfaces.costing.ICostProductCategoryOriginCountry>(this.ENV.DSP_URL + "CostParameterProductCategoryOriginCountry/GetProductCategoryOriginCountryList", {
                    showAllActive: '@showAllActive',
                    entityId: '@entityId',
                    originCountryId: '@originCountryId',
                    productCategoryId: '@productCategoryId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            saveProductCategoryOriginCountry(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CostParameterProductCategoryOriginCountry/SaveProductCategoryOriginCountry", {
                });
            }

            removeProductCategoryOriginCountry(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CostParameterProductCategoryOriginCountry/RemoveProductCategoryOriginCountry", {
                    cpuId: '@cpuId',
                });
            }


        }

    }

    angular.module("app").service("costParameterProductCategoryOriginCountryService", services.costing.costParameterProductCategoryOriginCountryService);
}